import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { filter, map, take, distinctUntilChanged } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BeeService } from '../bee/bee.service';
import { BeeTransporterLocation } from '../bee-transporter/bee-transporter.component';
import { Photo } from '../photo/photo';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  open$ = new ReplaySubject<boolean>(1);
  photos$ = new ReplaySubject<Photo[]>(1);

  constructor(private router: Router, private bee: BeeService, private route: ActivatedRoute) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => (e as NavigationEnd).urlAfterRedirects.split('#')[0]),
      distinctUntilChanged())
    .subscribe(async () => {
      const paramMap = await this.route.children[0].children[0].paramMap.pipe(take(1)).toPromise();
      const uid = paramMap.get('category_uid');
      if (!uid) this.close();
    });

    this.open$.pipe(filter(b => b === false)).subscribe(async b => {
      console.log(b);
      const paramMap = await this.route.children[0].children[0].paramMap.pipe(take(1)).toPromise();
      const uid = paramMap.get('category_uid');
      if (uid) this.router.navigate(['/portfolio']);
    });
  }

  open() {
    this.open$.next(true);
    document.body.classList.add('gallery-open');
    this.bee.move(BeeTransporterLocation.GALLERY_FOOTER);
  }

  close() {
    this.open$.next(false);
    document.body.classList.remove('gallery-open');
    this.bee.move(BeeTransporterLocation.HEADER);
  }

  setPhotos(photos: Photo[]) {
    this.photos$.next(photos);
  }
}
