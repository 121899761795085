import { InfoPage, Step, Price } from "./info-page";
import { fromDocument as photoFromDocument }  from "../photo/photo-util"
import { RichText } from "../prismic/prismic.service";

export function fromDocument(pre: any) {
  console.log(pre);
  let priceIndex = 0;
  const infoPage: InfoPage = {
    uid: pre.uid,
    title: 'Info & Tarieven',
    coverColor: pre.data.cover_color,
    coverPhoto: photoFromDocument(pre.data.cover_photo),
    introductionText: RichText.asHtml(pre.data.introduction_text),
    steps: pre.data.body1.map(r => {
      const p = r.primary;
      const step: Step = {
        name: p.name,
        photo: photoFromDocument(p.photo),
        index: p.index,
        text: RichText.asHtml(p.text)
      };
      return step;
    }),
    pricesOrHeadings: pre.data.body.map(r => {
      const p = r.primary;
      if (r.slice_type === 'heading') {
        return {
          type: 'heading',
          heading: {
            fragment: p.fragment
          }
        }
      } else {
        const price: Price = {
          name: p.name,
          text: RichText.asHtml(p.text),
          photo: photoFromDocument(p.photo),
          index: priceIndex++
        };
        return {
          type: 'price',
          price
        };
      }
    })
  }

  return infoPage;
}
