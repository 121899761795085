import { Photo, PhotoFormat } from "./photo";

export function fromDocumentArray(array: any[]) {
  return array.filter(pre => pre.photo && pre.photo.dimensions).map<Photo>(pre => {
    const photo = pre.photo;
    // if (!photo.dimensions) debugger;
    return this.fromDocument(photo);
  });
}

export function fromDocument(pre: any) {
  const o: Photo = {
    id: pre.url,
    alt: pre.alt,
    ratio: pre.dimensions.width / pre.dimensions.height,
    urls: {
      [PhotoFormat.MAIN]: pre.url,
      [PhotoFormat.UHD]: (pre.uhd || pre).url,
      [PhotoFormat.HD]: (pre.hd || pre).url,
      [PhotoFormat.MEDIUM]: (pre.medium || pre).url,
      [PhotoFormat.THUMB]: (pre.thumb || pre).url
    }
  }

  return o;
}

export function formatForSize(width: number) {
  if (width > 1280) {
    return PhotoFormat.UHD
  } else if (width > 800) {
    return PhotoFormat.HD
  } else {
    return PhotoFormat.MEDIUM
  }
}
