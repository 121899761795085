import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PrismicService } from '../prismic/prismic.service';
import { fromEvent, Subject } from 'rxjs';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { map, filter, distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';
import { HeaderService } from '../header/header.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { HomePage } from './home-page';
import { fromDocument } from './home-page-util';
import { Photo } from '../photo/photo';
import { getPrismicDocument } from '../prismic/prismic-preload';
import { PhotoService } from '../photo/photo.service';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('quoteZone', {static: true}) quoteZone: ElementRef;
  hasScrolled: boolean = false;
  needAttention: boolean = false;

  page: HomePage;

  private destroyed$ = new Subject<void>();

  constructor(private bootstrap: BootstrapService,
    private headerService: HeaderService,
    private analytics: AnalyticsService,
    private cd: ChangeDetectorRef,
    private photoService: PhotoService) {

    this.page = getPrismicDocument<HomePage>('home_page', 'home-page');

    fromEvent(window, 'scroll', {passive: true}).pipe(
      takeUntil(this.destroyed$),
      map(e => window.scrollY),
      filter(y => y > 10),
      distinctUntilChanged(),
      skip(2)
    ).subscribe(() => {
      this.hasScrolled = true;
      this.needAttention = false;
      this.cd.markForCheck();
    });
    
    fromEvent(window, 'scroll', {passive: true}).pipe(
      takeUntil(this.destroyed$),
      map(e => window.scrollY),
      distinctUntilChanged()
    ).subscribe(y => {
      y < 250 ? this.headerService.hideBorder() : this.headerService.showBorder();
    });
  }

  async ngOnInit() {
    // const api = await this.prismic.api();
    // const pre = await api.getByUID('home_page', 'home-page');
    // this.page = fromDocument(pre);

    this.cd.markForCheck();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.hasScrolled) this.needAttention = true;
      this.cd.markForCheck();
    }, 8000);
  }

  scrollDown() {
    const el = this.quoteZone.nativeElement as HTMLElement;
    el.scrollIntoView({behavior: 'smooth'});

    this.analytics.track('Homepage navigation', 'click_arrow_scroll_down');
  }

  private slideshowLoadedN = 0;
  private firstPhotoLoaded = false;
  slideshowLoadedPhoto(photo: Photo) {
    this.slideshowLoadedN++;
    console.log('HP Loaded homepage photo:', photo, 'n', this.firstPhotoLoaded, 'fpl', this.firstPhotoLoaded);
    if (photo === this.page.photos[0]) this.firstPhotoLoaded = true;
    if (this.firstPhotoLoaded && this.slideshowLoadedN >= 2) {
      // Move bee when 2 pictures loaded, including first picture
      this.bootstrap.pageLoaded();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  clickReadMore() {
    this.analytics.track('Homepage about read more', 'click');
  }

  clickTile(name: string) {
    this.analytics.track('Homepage tile', `click_${name}`);
  }

  clickSocialIcon(name: string) {
    this.analytics.track('Homepage social', `click_${name}`);
  }

  getTilePhotoUrl(photo: Photo) {
    const o = this.photoService.getMostAppropriateUrlAndFormat(photo, (window.innerWidth / 2) * window.devicePixelRatio);
    return o.url;
  }
  
  getAboutPhotoUrl(photo: Photo) {
    const o = this.photoService.getMostAppropriateUrlAndFormat(photo, (window.innerWidth / 1.5) * window.devicePixelRatio);
    return o.url;
  }
}
