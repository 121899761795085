import { Injectable } from '@angular/core';
import { PrismicService, Predicates } from '../prismic/prismic.service';
import { PhotoService } from '../photo/photo.service';
import { Category } from './category';
import { fromDocument } from './category-util';
import { getPrismicDocuments } from '../prismic/prismic-preload';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private categories: Category[];

  constructor(private prismic: PrismicService, private photoService: PhotoService) { }

  getAll(): Category[] {
    return getPrismicDocuments<Category>('category');
  }

  async fetchAll(): Promise<Category[]> {
    if (this.categories) return this.categories;
    const api = await this.prismic.api();
    const response = await api.query(Predicates.at('document.type', 'category'), null);
    this.categories = response.results.map(pre => fromDocument(pre)).sort((a, b) => a.index - b.index);
    return this.categories;
  }

  async fetchByUID(uid: string): Promise<Category> {
    await this.fetchAll();
    return this.categories.find(c => c.uid === uid);
  }

}
