import { Component, OnInit } from '@angular/core';
import { PrismicService, RichText, Predicates } from '../prismic/prismic.service';
import { FaqQuestion } from '../question/question';

@Component({
  selector: 'question-page',
  templateUrl: './question-page.component.html',
  styleUrls: ['./question-page.component.scss', '../styles/icon-button.scss']
})
export class QuestionPageComponent implements OnInit {
  questions: FaqQuestion[];
  opened = new Map<FaqQuestion, boolean>();

  constructor(private prismic: PrismicService) { }

  async ngOnInit() {
    const api = await this.prismic.api();
    const response = await api.query(Predicates.at('document.type', 'faq_question'), null);
    const results = (response as any).results;
    console.log(results);

    this.questions = results.map(result => {
      const data = result.data;
      return ({
        title: data.question,
        answer: RichText.asHtml(data.answer),
        priority: data.priority
      });
    });
    this.questions = this.questions.sort((a, b) => a.priority - b.priority);
  }

  toggle(question: FaqQuestion) {
    this.opened.set(question, !this.opened.get(question));
  }

  isOpened(question) {
    return this.opened.get(question);
  }
}
