import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { RecaptchaService } from '../recaptcha/recaptcha.service';
import { CookieAcceptationService } from '../cookie-acceptation/cookie-acceptation.service';

@Component({
  selector: 'enquire-form',
  templateUrl: './enquire-form.component.html',
  styleUrls: ['./enquire-form.component.scss', '../styles/icon-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnquireFormComponent implements OnInit, AfterViewInit {
  token: string;
  showForm = false;
  formData = {name: '', email: '', question: '', token: undefined};
  recaptchaLoaded = false;
  cookiesAccepted = false;
  @ViewChild('recaptchaBadge', {static: true})
  private recaptchaBadge: ElementRef<HTMLDivElement>;

  questionForm = new FormGroup({
    name: new FormControl(this.formData.name),
    email: new FormControl(this.formData.email),
    question: new FormControl(this.formData.question),
  });

  constructor(private http: HttpClient,
    private recaptcha: RecaptchaService,
    private cd: ChangeDetectorRef,
    private cas: CookieAcceptationService) {
  }

  ngOnInit() {
  }

  async ngAfterViewInit() {
    await this.cas.acceptation$.toPromise();
    this.cookiesAccepted = true;
    this.recaptcha.load(this.recaptchaBadge);
    this.recaptcha.loaded$.subscribe(() => {
      this.recaptchaLoaded = true;
      this.cd.markForCheck();
    });
    // this.cd.markForCheck();
  }

  enquirySending = false;
  enquirySent = false;
  enquiryFailed = false;
  async sendEnquiry($event) {
    this.enquirySending = true;
    $event.preventDefault();
    this.cd.markForCheck();

    await new Promise(res => setTimeout(res, 1000));
    this.formData.token = await this.recaptcha.fetchToken();
    this.http.post('/api/enquire', this.formData, {headers: new HttpHeaders({'Content-Type' : 'application/json'})}).subscribe(
      success => {
        this.enquirySent = true;
        this.cd.markForCheck();
      },
      error => {
        this.enquiryFailed = true;
        this.cd.markForCheck();
      }
    );
  }

  getButtonText() {
    if (this.enquiryFailed) return 'Versturen mislukt. Probeer opnieuw.';
    if (this.enquirySent) return 'Bedankt voor uw vraag!';
    if (!this.cookiesAccepted) return 'Accepteer cookies om een vraag te sturen.';
    return this.enquirySending ? 'Wordt verstuurd...' : 'Verstuur';
  }
}
