import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class CookieAcceptationService {
  acceptation$ = new ReplaySubject<boolean>(1);
  
  constructor(private http: HttpClient, private analytics: AnalyticsService) {
    const v = JSON.parse((<HTMLMetaElement>document.querySelector('meta[name=cookie-acceptation]')).content);
    this.acceptation$.next(v);
    if (v) this.acceptation$.complete();
  }

  accept() {
    this.http.post('/api/cookies/accept', {}).subscribe(() => {
      this.acceptation$.next(true);
      this.acceptation$.complete();
      this.analytics.track('Cookies', 'accept_cookies');
    });
  }
}
