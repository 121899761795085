import { Component, OnInit, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FooterService } from './footer.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit {
  constructor(private footerService: FooterService,
    private elementRef: ElementRef<HTMLElement>,
    private cd: ChangeDetectorRef) {
    footerService.shown$.subscribe(v => {
      if (v) {
        this.elementRef.nativeElement.classList.remove('invisible');
      } else {
        this.elementRef.nativeElement.classList.add('invisible');
      }
    });
  }

  ngAfterViewInit() {
    this.cd.detach();
  }
}
