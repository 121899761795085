import { Component, OnInit, Input, HostListener, Output, EventEmitter, SimpleChanges, OnChanges, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PhotoService } from '../photo/photo.service';
import { onImageLoad } from '../image-load/image-load';
import { CommonPageData } from '../common-page/common-page';

@Component({
  selector: 'common-page-header',
  templateUrl: './common-page-header.component.html',
  styleUrls: ['./common-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonPageHeaderComponent implements OnInit, OnChanges {

  @Input() data: CommonPageData;
  @Output() loaded = new EventEmitter<boolean>();
  coverPhotoLoaded = false;
  coverPhotoUrl: string;

  constructor(private photoService: PhotoService,
    private elementRef: ElementRef<HTMLElement>,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.data.currentValue) return;
    this.elementRef.nativeElement.style.backgroundColor = this.data.coverColor;
    this.cd.markForCheck();
    const r = this.resize();
    if (!r) return;
    r.then(() => {
       this.loaded.emit(true);
       setTimeout(() => {
        this.coverPhotoLoaded = true;
        this.cd.markForCheck();
       }, 50);
    });
  }

  @HostListener('window:resize')
  resize() {
    if (!this.data.coverPhoto) return;
    return new Promise((res, rej) => {
      const photo = this.data.coverPhoto;
      const o = this.photoService.getMostAppropriateUrlAndFormat(photo, window.innerWidth * window.devicePixelRatio);
        this.coverPhotoUrl = o.url;
        if (!this.photoService.isFetched(photo, o.format)) {
          onImageLoad(o.url).then(() => {
            this.photoService.markAsFetched(photo, o.format);
            res();
          });
        } else {
          res();
        }
    });
  }
}
