import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AnalyticsService } from '../analytics/analytics.service';

@Component({
  selector: 'review-page',
  templateUrl: './review-page.component.html',
  styleUrls: ['./review-page.component.scss', '../styles/icon-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewPageComponent implements OnInit {

  constructor(private analytics: AnalyticsService) { }

  ngOnInit() {
  }

  clickReview(name: string) {
    this.analytics.track('Review button', `click_${name}`);
  }
}
