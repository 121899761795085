const map = new Map<string, Promise<void>>();

export function onImageLoad(src: string): Promise<void> {
  if (map.has(src)) {
    return map.get(src);
  }

  map.set(src, new Promise((res, rej) => {
    const img = new Image();
    img.onload = () => res();
    img.onerror = () => rej();
    img.src = src;
  }));

  return map.get(src);
}
