import { Component, OnInit, ElementRef } from '@angular/core';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'mask',
  template: '',
  styleUrls: ['./mask.component.scss']
})
export class MaskComponent implements OnInit {

  constructor(private elementRef: ElementRef, private bootstrap: BootstrapService) { }

  ngOnInit() {
    const el = this.elementRef.nativeElement as HTMLElement;
    this.bootstrap.done$.subscribe(b => {
      el.classList.add('gone');
    });
    this.bootstrap.done$.pipe(delay(500)).subscribe(b => {
      el.classList.add('really-gone');
    });
  }

}
