import { Component, OnInit, ElementRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { BeeService } from '../bee/bee.service';
import { fromEvent, merge, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// from bee-transporter.component.scss
const BEE_X_OFFSET = 12;
const BEE_Y_OFFSET = 6;
const BEE_WIDTH = 75;
const BEE_HEIGHT = 64; 

export const enum BeeTransporterLocation {
  MIDDLE = 0,
  HEADER = 1,
  GALLERY_FOOTER = 2,
  ARBITRARY = 3
}

@Component({
  selector: 'bee-transporter',
  templateUrl: './bee-transporter.component.html',
  styleUrls: ['./bee-transporter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeeTransporterComponent implements OnInit, AfterViewInit {
  private location = BeeTransporterLocation.HEADER;
  private done = false;

  constructor(private elementRef: ElementRef<HTMLElement>,
    private bootstrap: BootstrapService,
    private bee: BeeService) {
      
    bee.move$.subscribe(location => {
      this.location = location;
      if (this.done) this.move();
    });

    merge(
      fromEvent(window, 'resize'),
      fromEvent(window, 'fullscreenchange')
    ).pipe(debounceTime(100)).subscribe(() => {
        this.move(); // for header going from mobile to normal, and also for gallery.
      });
  }

  ngOnInit() {
    const el = this.elementRef.nativeElement;
  }

  ngAfterViewInit() {
    this.move(BeeTransporterLocation.MIDDLE, false);

    this.bootstrap.done$.subscribe(() => this.move());
    this.bootstrap.done$.subscribe(() => this.done = true);
  }

  move(location = this.location, smooth = true) {
    const el = this.elementRef.nativeElement;
    smooth ? el.classList.add('movable') :  el.classList.remove('movable');
    this.bee.enableFlapping();
    if (this.done) this.bee.disableFlapping(); // will queue flap stop
    switch(location) {
      case BeeTransporterLocation.HEADER:
        this.moveToHeader();
        break;
      case BeeTransporterLocation.GALLERY_FOOTER:
        this.moveToGalleryFooter();
        break;
      case BeeTransporterLocation.MIDDLE:
        this.moveToMiddle();
        break;
      case BeeTransporterLocation.ARBITRARY:
        break;
    }
  }

  moveToMiddle() {
    const dx = (window.innerWidth - BEE_WIDTH)/2;
    const dy = (window.innerHeight - BEE_HEIGHT)/2;
    this.moveToPoint(dx, dy, 2)
  }
  
  moveToHeader() {
    window.innerWidth < 750 ? this.moveToPoint() : this.moveToPoint(29, 12);
  }
  
  moveToGalleryFooter() {
    const dx = 5 + 24 + 20 + 12; // ehm
    const dy = window.innerHeight - 62 - 24 + 4 + 1; // ehm
    const showBee = window.innerWidth >= 750; // match with footer close knob
    // Under 750,
    // There's not enough place for the bee -> move it out of the way (-100px)
    showBee ? this.moveToPoint(dx, dy, 0.55) : this.moveToPoint(-100, 0, 1, true);
  }

  moveToPoint(x?: number, y?: number, scale: number = 1, rotate: boolean = false) {
    const el = this.elementRef.nativeElement;
    
    let a = [];
    if (x) {
      const dx = x - BEE_X_OFFSET;
      if (dx) a.push(`translateX(${dx}px)`); 
    }
    if (y) {
      const dy = y - BEE_Y_OFFSET;
      if (dy) a.push(`translateY(${dy}px)`); 
    }
    if (scale !== 1) a.push(`scale(${scale})`);
    a.push(rotate ? 'rotate(180deg)' : 'rotate(0deg)');
    el.style.webkitTransform = el.style.transform = a.join(' ');
  }
}
