import { Injectable } from '@angular/core';

interface Link {
  name: string,
  kebabName: string,
  only?: string[]
}

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  links: Link[] = [
    {
      name: 'Portfolio',
      kebabName: 'portfolio'
    },
    // {
    //   name: 'Contact',
    //   kebabName: 'contact'
    // },
    {
      name: 'Info & Tarieven',
      kebabName: 'info-en-tarieven'
    },
    {
      name: 'Over Inge',
      kebabName: 'over-inge'
    },
    {
      name: 'FAQ',
      kebabName: 'faq',
      only: ['menu']
    },
    {
      name: 'Pasfoto',
      kebabName: 'pasfoto'
    }
  ];

  constructor() { }
}
