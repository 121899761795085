import { Component, OnInit, ElementRef, ViewChild, HostListener, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, NgZone } from '@angular/core';
import { GalleryService } from './gallery.service';
import { PhotoService } from '../photo/photo.service';
import { fromEvent, interval, Subject, of } from 'rxjs';
import { distinctUntilChanged, map, debounceTime, take, scan, merge, first, skip, repeatWhen, mapTo, delay, takeUntil, filter, tap } from 'rxjs/operators';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { Photo, PhotoFormat } from '../photo/photo';

const PHOTO_SPACING = 16;
const TRANSITION_DURATION = '500ms';
const THUMB_WIDTH = 44;
const THUMB_HEIGHT = 44;
const THUMB_SPACING = 3;

interface PhotoElementRef {
  photo: Photo;
  element: HTMLDivElement;
  thumbElement: HTMLButtonElement;
  width: number;
  offset: number;
  loading: boolean; // or already loaded
}

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnInit, AfterViewInit {
  // photos: Photo[];
  thumbIndex = 0;
  lastPhotoIndex = 0;
  open = false;
  currentlySelectedD = undefined;
  @ViewChild('photoRow', {static: true}) photoRowEl: ElementRef<HTMLElement>;
  @ViewChild('viewRow', {static: true}) viewRowEl: ElementRef<HTMLElement>;
  @ViewChild('thumbsRow', {static: true}) thumbsRowEl: ElementRef<HTMLElement>;
  @ViewChild('clickMask', {static: true}) clickMaskEl: ElementRef<HTMLElement>;

  private photoRowElWidth: number;
  private selectedPhotoIndex$ = new Subject<number>();
  private loadPhotoIndex$ = new Subject<number>();
  private photoRefs: PhotoElementRef[] = [];
  private height: number;
  private recalculatePhotoRowWidth$ = new Subject<void>();

  constructor(private elementRef: ElementRef<HTMLElement>,
    private galleryService: GalleryService,
    private photoService: PhotoService,
    private cd: ChangeDetectorRef,
    private bootstrap: BootstrapService,
    private zone: NgZone) {

    this.zone.runOutsideAngular(() => {

      const resize$ = fromEvent(window, 'resize').pipe(
        merge(fromEvent(document, 'fullscreenchange')),
        debounceTime(100),
        filter(() => this.open));

      const recalculate$ = resize$.pipe(
        merge(this.galleryService.photos$),
        debounceTime(100),
        map(() => ({height: window.innerHeight, width: window.innerWidth})),
        filter(() => this.open)
      );
      
      this.loadPhotoIndex$.subscribe(i => {
        const ref = this.photoRefs[i];

        if (ref.loading) return;

        console.warn(ref.width);
        const o = this.photoService.getMostAppropriateUrlAndFormat(ref.photo, ref.width * window.devicePixelRatio);

        const img = new Image();
        img.onload = () => {
          setTimeout(() => ref.element.classList.add('shown'), 100);
          this.photoService.markAsFetched(ref.photo, o.format);
        }
        img.src = o.url;
        img.classList.add('view-photo-image');

        const container = document.createElement('div');
        container.classList.add('view-photo-image-container');
        ref.element.appendChild(container);
        container.appendChild(img);
        ref.loading = true;
      });

      recalculate$.subscribe(d => {
        const maxHeightByWidth = d.width / 1.8;

        this.height = Math.min(Math.max(200, d.height - 100), 500, maxHeightByWidth);

        const marginTop = (d.height - this.height) / 2;
        this.viewRowEl.nativeElement.style.marginTop = `${marginTop}px`;
        let offset = 0;

        if (this.photoRefs.length) {
          for (const ref of this.photoRefs) {
            const element = ref.element;
            const width = this.height * ref.photo.ratio;
            element.style.height = `${this.height}px`;
            element.style.width = `${this.height * ref.photo.ratio}px`;
            element.style.left = `${offset}px`; 
            offset += width + PHOTO_SPACING;
            ref.width = width;
            ref.offset = offset;
          }
        }
      });

      const loadIndex = (i: number) => {
        if (i >= 0 && i < this.photoRefs.length) this.loadPhotoIndex$.next(i);
      }

      // try to implement lastI with repeatWhen or something
      this.selectedPhotoIndex$.pipe(
        merge(recalculate$.pipe(mapTo(undefined))) // initial photo, and on resize
      ).subscribe(i => {
        if (i === undefined) i = this.lastPhotoIndex;
        if (i === undefined || !this.photoRefs.length) return;
        this.photoRefs[this.lastPhotoIndex].thumbElement.classList.remove('selected');

        const ref = this.photoRefs[i];
        ref.thumbElement.classList.add('selected');
        const d = - ref.offset + PHOTO_SPACING + (window.innerWidth + ref.width) / 2;
        this.currentlySelectedD = d;
        this.writeD(d);

        // TODO: this formula is meh, and the 1.5 is arbitrary
        const imagesOnHalfScreen = Math.ceil(Math.max(window.innerWidth / (ref.width * 1.5), 2));
        console.log('Fitting', imagesOnHalfScreen * 2 - 1);
        // generates [0, 1], [0, 1, -1, 2], [0, 1, -1, 2, -2, 3], ...
        for (let o = 0; o <= imagesOnHalfScreen; o++) {
          setTimeout(() => {
            if (o > 0 && o < imagesOnHalfScreen) loadIndex(i - o);
          }, o * 100);
          setTimeout(() => {
            loadIndex(i + o);
          }, o * 100 + 50);
        }

        this.lastPhotoIndex = i;

        if (i > 0 && !this.isThumbVisible(i)) {
          const fittingInside = this.getThumbsFittingInside();
          this.setThumbIndexContained(i - Math.ceil(fittingInside / 2) + 1);
        }
      });

      recalculate$.pipe(
        map(() => window.innerWidth),
        distinctUntilChanged(),
        merge(this.recalculatePhotoRowWidth$),
        map(() => window.innerWidth)
      ).subscribe(w => {
        this.photoRowElWidth = this.photoRowEl.nativeElement.offsetWidth - 64;
        this.setThumbsStyle();
        this.cd.markForCheck();
      });

      resize$.subscribe(() => {
        this.viewRowEl.nativeElement.style.transitionDuration = '0ms';
      });
      resize$.pipe(debounceTime(500)).subscribe(() => {
        this.viewRowEl.nativeElement.style.transitionDuration = TRANSITION_DURATION;
      });

      this.galleryService.photos$.subscribe(photos => {
        // if (this.photos === photos) return;
        // this.photos = photos;
        this.viewRowEl.nativeElement.innerHTML = '';
        this.thumbsRowEl.nativeElement.innerHTML = '';
        this.lastPhotoIndex = 0;
        this.thumbIndex = 0;
        this.viewRowEl.nativeElement.style.transform = `translateX(500px)`; // nice opening effect
        this.thumbsRowEl.nativeElement.style.transform = '';

        // this.selectedPhotoIndex$.next(0);
        // this.cd.markForCheck();
        this.bootstrap.pageLoaded();

        let offset = 0;
        this.photoRefs = photos.map(photo => {
          const i = photos.indexOf(photo);
          const element = document.createElement('div');
          const width = this.height * photo.ratio;
          // element.style.height = `${this.height}px`;
          // element.style.width = `${this.height * photo.ratio}px`;
          // element.style.left = `${offset}px`;
          // offset += width + 16;
          element.classList.add('view-photo');
          element.addEventListener('click', () => this.clickPhoto(i));
          this.viewRowEl.nativeElement.appendChild(element);

          const thumbElement = document.createElement('button');
          thumbElement.classList.add('photo-thumb');
          thumbElement.addEventListener('click', () => this.clickPhoto(i));
          const thumbMaskElement = document.createElement('div');
          thumbMaskElement.classList.add('photo-thumb-mask');
          thumbElement.appendChild(thumbMaskElement);
          this.thumbsRowEl.nativeElement.appendChild(thumbElement);

          const elRef: PhotoElementRef = {
            photo, element, thumbElement, width, offset, loading: false
          }

          return elRef;
        });

        this.cd.markForCheck();

        interval(120).pipe(
          take(photos.length),
          takeUntil(this.galleryService.photos$.pipe(skip(1))), // stop when new category opens
        ).subscribe(i => {
          const photoRef = this.photoRefs[i];
          const url = photoRef.photo.urls[PhotoFormat.THUMB];

          const img = new Image();
          img.onload = () => {
            // Somehow, the line below makes sure the thumb images don't shock
            // to the full size.
            this.recalculatePhotoRowWidth$.next();
            photoRef.thumbElement.classList.add('shown');
          }
          img.src = url;

          if (photoRef.photo.ratio > 1) {
            img.style.marginLeft = `-${THUMB_WIDTH - THUMB_HEIGHT / photoRef.photo.ratio}px`;
          } else {
            img.style.width = `${THUMB_WIDTH}px`;
            img.style.height = 'auto'; 
          }
          img.classList.add('photo-thumb-image');

          const imageContainer = document.createElement('div');
          imageContainer.classList.add('photo-thumb-image-container');
          imageContainer.appendChild(img);
          photoRef.thumbElement.appendChild(imageContainer);
        });
      });
    });
  }

  ngOnInit() {
    this.zone.runOutsideAngular(() => {

      this.galleryService.open$.subscribe(b => {
        this.open = b;
        const el = this.elementRef.nativeElement as HTMLElement;
        b ? el.classList.add('open') : el.classList.remove('open');
        this.thumbIndex = 0;
        this.recalculatePhotoRowWidth$.next();
      });

    });
  }

  startX = undefined;

  ngAfterViewInit() {
    let swipeStarted = false
    let swiping = false;

    for(let o of [{m: 'down', t: 'start', v: true}, {m: 'up', t: 'end', v: false}]) {
      fromEvent(this.clickMaskEl.nativeElement, `touch${o.t}`, {passive: true})
        .pipe(
          merge(fromEvent(this.clickMaskEl.nativeElement, `mouse${o.m}`)),
          tap(e => e.preventDefault()),
          debounceTime(10))
        .subscribe((e: MouseEvent|TouchEvent) => {
          const p: {pageX: number} = (e as TouchEvent).changedTouches ? (e as TouchEvent).changedTouches.item(0) : (e as MouseEvent);
          if (swiping && !o.v) {
            this.viewRowEl.nativeElement.style.transitionDuration = null;
            const d = this.startX - p.pageX;
            if (Math.abs(d) < 40) {
              this.selectCurrentPhoto();
            } else {
              d > 0 ? this.selectNextPhoto(null, true) : this.selectPrevPhoto(null, true);
            }
          }
          this.startX = p.pageX;
          swipeStarted = o.v;
          swiping = swiping && o.v;
        });
    }

    fromEvent(this.clickMaskEl.nativeElement, 'touchmove', {passive: true})
      .pipe(
        merge(fromEvent(this.clickMaskEl.nativeElement, 'mousemove')),
        tap(e => e.preventDefault()),
        debounceTime(10))
      .subscribe((e: MouseEvent|TouchEvent) => {
        const p: {pageX: number} = (e as TouchEvent).changedTouches ? (e as TouchEvent).changedTouches.item(0) : (e as MouseEvent);
        if (Math.abs(this.startX - p.pageX) > 20 && swipeStarted) swiping = true;
        this.viewRowEl.nativeElement.style.transitionDuration = swiping ? '0ms' : null;
        if (swiping) this.writeD(this.currentlySelectedD - this.startX + p.pageX);
      });

    fromEvent(document, 'fullscreenchange').subscribe(() => {
      if (!(document as any).fullscreenElement) {
        this.requestedFullscreen = false;
        this.elementRef.nativeElement.classList.remove('fullscreened');
      }
    })
  }

  clickPhoto(i: number) {
    this.selectedPhotoIndex$.next(i);
  }

  selectPrevPhoto(e?: MouseEvent, reselectCurrent?: boolean) {
    if (e && Math.abs(this.startX - e.clientX) > 20) return;
    if (this.lastPhotoIndex > 0) this.selectedPhotoIndex$.next(this.lastPhotoIndex - 1);
    else if (reselectCurrent) this.selectCurrentPhoto();
  }

  selectNextPhoto(e?: MouseEvent, reselectCurrent?: boolean) {
    if (e && Math.abs(this.startX - e.clientX) > 20) return;
    if (this.lastPhotoIndex < this.photoRefs.length - 1) this.selectedPhotoIndex$.next(this.lastPhotoIndex + 1);
    else if (reselectCurrent) this.selectCurrentPhoto();
  }

  selectCurrentPhoto() {
    this.selectedPhotoIndex$.next(this.lastPhotoIndex);
  }

  clickArrowLeft() {
    this.setThumbIndexContained(this.thumbIndex - 1);
  }

  clickArrowRight() {
    this.setThumbIndexContained(this.thumbIndex + 1);
  }

  isRowAtStart() {
    if (!this.photoRefs) return false;
    return this.thumbIndex === 0;
  }

  isRowAtEnd() {
    if (!this.photoRefs) return false;
    const fittingInside = this.getThumbsFittingInside();
    
    if (this.thumbIndex >= this.photoRefs.length - fittingInside) return true;

    return false;
  }

  setThumbsStyle() {
    if (!this.photoRefs) return;
    const fittingInside = this.getThumbsFittingInside();
    const leftover = this.getThumbsLeftover();

    let d;
    if (this.thumbIndex >= this.photoRefs.length - fittingInside) {
      d = (this.photoRefs.length - fittingInside) * (THUMB_WIDTH + THUMB_SPACING) - leftover - 3; 
    } else {
      d = this.thumbIndex * (THUMB_WIDTH + THUMB_SPACING);
    }

    this.thumbsRowEl.nativeElement.style.webkitTransform = 
    this.thumbsRowEl.nativeElement.style.transform = `translateX(-${d}px)`;
  }

  setThumbIndexContained(i: number) {
    this.thumbIndex = Math.max(0, Math.min(this.photoRefs.length - this.getThumbsFittingInside(), i));
    this.setThumbsStyle();
    this.cd.markForCheck();
  }

  isThumbVisible(i: number) {
    const low = this.thumbIndex;
    const high = low + this.getThumbsFittingInside();
    return low <= i && i < (high - 1);
  }

  getThumbsFittingInside() {
    return Math.floor(this.photoRowElWidth / (THUMB_WIDTH + THUMB_SPACING));
  }

  getThumbsLeftover() {
    return this.photoRowElWidth % (THUMB_WIDTH + THUMB_SPACING); 
  }

  writeD(d: number) {
    this.viewRowEl.nativeElement.style.transform = `translateX(${d}px)`;
  }

  @HostListener('window:keydown', ['$event.keyCode'])
  pressKey(keyCode) {
    if (keyCode === 37) this.selectPrevPhoto();
    else if (keyCode == 39) this.selectNextPhoto();
    else if (keyCode == 27) this.galleryService.close();
  }

  getThumbBackgroundStyle(photo: Photo) {
    return `url(${photo.urls[PhotoFormat.THUMB]})`;
  }

  requestedFullscreen = false;
  clickFullscreen() {
    this.requestedFullscreen = true;
    const body = document.body;
    if (body.requestFullscreen) {
      body.requestFullscreen();
    } else if ((body as any).webkitRequestFullscreen) {
      (body as any).webkitRequestFullscreen();
    } else {
      return;
    }
    this.elementRef.nativeElement.classList.add('fullscreened');
  }

  clickBack() {
    if (this.requestedFullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      }
      this.elementRef.nativeElement.classList.remove('fullscreened');
    }
    this.galleryService.close();
  }
}
