import { Directive, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';

@Directive({
  selector: '[backgroundImage]'
})
export class BackgroundImageDirective implements OnChanges {
  @Input('backgroundImage') url: string;
  @Input('backgroundImageActive') active: boolean = true;

  private wasActive = false;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges) {
    this.wasActive = this.wasActive || this.active;

    if (this.url && this.wasActive) {
       this.elementRef.nativeElement.style.backgroundImage = `url(${this.url})`;
    }
    if (!this.wasActive && this.elementRef.nativeElement.style.backgroundImage) {
      this.elementRef.nativeElement.style.backgroundImage = null;
    }
  }

}
