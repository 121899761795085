import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../category/category.service';
import { GalleryService } from '../gallery/gallery.service';
import { take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'portfolio-category-page',
  template: '',
  styleUrls: ['./portfolio-category-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioCategoryPageComponent implements OnInit {

  // loaded hook is called in gallery
  constructor(private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private galleryService: GalleryService,
    private title: Title) {
  }

  async ngOnInit() {
    this.galleryService.open();

    const paramMap = await this.activatedRoute.paramMap.pipe(take(1)).toPromise();
    const uid = paramMap.get('category_uid');
    const category = await this.categoryService.fetchByUID(uid);
    this.title.setTitle(`Zoemin Fotografie - Portfolio / ${category.name}`);

    this.galleryService.setPhotos(category.photos);
  }

}
