import { Injectable, NgZone } from '@angular/core';
import { ReplaySubject, Observable, zip } from 'rxjs';
import { mapTo, filter, map, take, distinctUntilChanged } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FooterService } from '../footer/footer.service';
import { MenuService } from '../menu/menu.service';
import { HeaderService } from '../header/header.service';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  readonly typekitLoaded$ = new ReplaySubject<boolean>(1);
  readonly done$: Observable<boolean>;
  readonly pageLoaded$ = new ReplaySubject<boolean>(1);
  readonly routerData$: Observable<Data>;

  constructor(private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private footerService: FooterService,
    private menuService: MenuService,
    private headerService: HeaderService,
    private analytics: AnalyticsService) {


    this.routerData$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => {
        let route = this.route.snapshot;
        while (route.firstChild) route = route.firstChild;
        return route.data;
      })
    );
    this.setupTypekitLoaded();
    this.setupPageLoaded();
    this.done$ = zip(this.typekitLoaded$, this.pageLoaded$).pipe(mapTo(true), take(1));
    this.done$.subscribe(() => {
      console.log('Bootstrap done');
    });
    this.setupAnalytics();
    this.setupOtherRouteBindings();
  }

  pageLoaded() {
    this.pageLoaded$.next(true);
  }

  setupPageLoaded() {
    this.routerData$.subscribe(data => {
      if (!data.hasLoadedHook) this.pageLoaded$.next(true);
    });

    this.typekitLoaded$.subscribe(() => console.log('Typekit loaded'));
  }

  setupTypekitLoaded() {    
    setTimeout(() => {
      if ((window as any).typekitMuseoActive) {
        this.typekitLoaded$.next(true);
      } else {
        (window as any).typekitMuseoActiveCallback = () => {
          this.zone.run(() => {
            this.typekitLoaded$.next(true);
          });
        }
      }
    }, 1000);

    // Slow network -> fallback font -> no callback
    setTimeout(() => {
      if (!(window as any).typekitMuseoActive) {
        this.typekitLoaded$.next(true);
      }
    }, 10000);
  }

  setupOtherRouteBindings() {
    const viewportEl = (<HTMLMetaElement>document.querySelector('meta[name=viewport]'));
    const initialViewport = viewportEl.content;
    this.routerData$.subscribe(data => {
      this.menuService.close();

      if (!data.hasDynamicTitle) {
        const title = data.title;
        if (title) {
          this.title.setTitle(`Zoemin Fotografie - ${title}`);
        } else {
          this.title.setTitle('Zoemin Fotografie - Inge Nijs');
        }
      }

      const hideFooter = data.hideFooter;
      hideFooter ? this.footerService.hide() : this.footerService.show();

      const hideHeaderBorder = data.hideHeaderBorder;
      hideHeaderBorder ? this.headerService.hideBorder() : this.headerService.showBorder();

      const disableZoom = data.disableZoom;
      viewportEl.content = initialViewport + (disableZoom ?  ', maximum-scale=1' : '');
    });
  }

  setupAnalytics() {
    this.done$.subscribe(() => this.analytics.time('Bootstrap', 'load_done'));
    this.typekitLoaded$.subscribe(() => this.analytics.time('Bootstrap', 'loaded_typekit'));
    this.pageLoaded$.pipe(take(1)).subscribe(() => this.analytics.time('Bootstrap', 'first_page_loaded'));
  }
}
