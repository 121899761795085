import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { CategoryService } from '../category/category.service';
import { PhotoService } from '../photo/photo.service';
import { onImageLoad } from '../image-load/image-load';
import { Category } from '../category/category';
import { getPrismicDocuments } from '../prismic/prismic-preload';

@Component({
  selector: 'portfolio-page',
  templateUrl: './portfolio-page.component.html',
  styleUrls: ['./portfolio-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioPageComponent implements OnInit, AfterViewInit {
  categories: Category[];
  loaded = {}

  constructor(private photoService: PhotoService,
    private categoryService: CategoryService,
    private cd: ChangeDetectorRef) {
    this.categories = this.categoryService.getAll().sort((a, b) => {
      return a.index > b.index ? 1 : -1;
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    for (let i = 0; i < this.categories.length; i++) {
      const category = this.categories[i];
      const o = this.photoService.getMostAppropriateUrlAndFormat(category.frontImage, window.innerWidth * window.devicePixelRatio / 2);
      if (this.photoService.isFetched(category.frontImage, o.format)) {
        setTimeout(() => {
          this.loaded[category.uid] = true;
          this.cd.markForCheck();
        }, 50 + i * 20);
      }
    }

  }

  getPhotoUrl(category: Category) {
    const o = this.photoService.getMostAppropriateUrlAndFormat(category.frontImage, window.innerWidth * window.devicePixelRatio / 2);
    if (!this.photoService.isFetched(category.frontImage, o.format)) {
      onImageLoad(o.url).then(() => {
        this.photoService.markAsFetched(category.frontImage, o.format);
        this.loaded[category.uid] = true;
        this.cd.markForCheck();
      });
    }
    return o.url;
  }
}
