import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { PhotoService } from '../photo/photo.service';
import { interval, Subject } from 'rxjs';
import { filter, takeUntil, take, tap, share } from 'rxjs/operators';
import { onImageLoad } from '../image-load/image-load';
import { Photo } from '../photo/photo';

const LOAD_OFFSET = 3;
const INTERVAL_TIME = 3000;

@Component({
  selector: 'slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideshowComponent implements OnInit, OnDestroy {
  private index: number = 0;
  showLink: boolean = false;
  private _photos: Photo[];
  @Output() loaded = new EventEmitter<Photo>();
  private destroyed$ = new Subject<void>();
  photoLoadedMap = {}

  @Input()
  set photos(photos: Photo[]) {
    this._photos = photos;
    if (!photos || !this._photos) return;
    for(let i = 0; i < LOAD_OFFSET; i++) {
      if (photos[i]) this.listenForLoad(photos[i]);
    }
  }

  get photos() {
    return this._photos;
  }

  constructor(private photoService: PhotoService,
    private cd: ChangeDetectorRef) {
      // Wait for the first photo to load before starting the timer
      this.loaded.pipe(filter(photo => photo === this.photos[0]), take(1)).subscribe(() => this.start());
      this.loaded.subscribe(photo => {
        this.photoLoadedMap[photo.id] = true;
      });
    }

  ngOnInit() {
  }

  listenForLoad(photo: Photo) {
    const o = this.photoService.getMostAppropriateUrlAndFormat(photo, window.innerWidth * window.devicePixelRatio);
    if (this.photoService.isFetched(photo, o.format)) {
      this.loaded.next(photo);
      return;
    }
    console.log('Going to load', photo, o.format, o.url);
    onImageLoad(o.url).then(() => {
      console.log('Fetched', photo, o.format, o.url);
      this.photoService.markAsFetched(photo, o.format);
      this.loaded.next(photo);
    });
  }

  start() {
    interval(INTERVAL_TIME)
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => {
      const photo = this.photos[(this.index + 1) % this.photos.length];
      const o = this.photoService.getMostAppropriateUrlAndFormat(photo, window.innerWidth * window.devicePixelRatio);
      if (!this.photoService.isFetched(photo, o.format)) return;
      if (this.index + LOAD_OFFSET < this.photos.length) this.listenForLoad(this.photos[this.index + LOAD_OFFSET]);
      this.index++;
      this.index %= this.photos.length;
      this.cd.detectChanges();
    });
  }

  getClasses(photo: Photo) {
    const photoIndex = this.photos.indexOf(photo);

    const classes: string[] = [];
    if (photoIndex === this.index) classes.push('active');
    if (photoIndex === this.index - 1) classes.push('post-active');
    if (!!this.photoLoadedMap[photo.id]) classes.push('loaded');

    return classes;
  }

  getPhotoUrl(photo: Photo) {
    const o = this.photoService.getMostAppropriateUrlAndFormat(photo, window.innerWidth * window.devicePixelRatio);
    return o.url;
  }

  photoTrackBy(index: number, item: Photo) {
    return item.id;
  }

  isAlmostActive(photo: Photo) {
    return this.photos.indexOf(photo) < this.index + 2;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
