import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AnalyticsService } from './analytics/analytics.service';
import { CommonPageService } from './common-page/common-page.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './styles/ui.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  constructor(private analytics: AnalyticsService) {
    this.analytics.start();
  }

  ngOnInit() {
  }

}
