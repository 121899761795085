import { Component, OnInit, ElementRef } from '@angular/core';
import { PrismicService } from '../prismic/prismic.service';
import { PhotoService } from '../photo/photo.service';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { fromDocument } from './info-page-util';
import { InfoPage, Step } from './info-page';
import { Photo } from '../photo/photo';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss']
})
export class InfoPageComponent implements OnInit {
  page: InfoPage;
  apiPromise: Promise<any>;

  constructor(private prismic: PrismicService,
    private photoService: PhotoService,
    private bootstrap: BootstrapService,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef<HTMLElement>) {
      this.page = {
        uid: 'info-page',
        coverColor: '#000000',
        title: 'Info & Tarieven',
        steps: [],
        pricesOrHeadings: [],
        introductionText: ''
      } as InfoPage
    }

  async ngOnInit() {
    const api = await this.prismic.api();
    this.apiPromise = api.getByUID('info_page', 'info-page').then(pre => {
      this.page = fromDocument(pre);
    });
  }

  async ngAfterViewInit() {
    await this.apiPromise;
    await new Promise(r => setTimeout(r, 1000));
    this.activatedRoute.fragment.subscribe(f => {
      this.scrollToFragment(f);
    });
  }

  getPhotoUrl(photo: Photo) {
    const o = this.photoService.getMostAppropriateUrlAndFormat(photo, (window.innerWidth / 3) * window.devicePixelRatio);
    return o.url;
  }

  headerLoaded() {
    this.bootstrap.pageLoaded$.next(true);
  }

  scrollToFragment(f: string) {
    const el =this.elementRef.nativeElement.querySelector(`#${f}`);
    el ? el.scrollIntoView({behavior: "smooth"}) : console.warn('No fragment with name', f);
  }
}
