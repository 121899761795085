import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styles: [`.svg-container { font-size: 0; }`]
})
export class IconComponent implements OnInit {

  iconSvgStr: string;

  @Input() icon;

  constructor() { }

  ngOnInit() {
    this.iconSvgStr = require(`./icons/${this.icon}.svg`);
  }

}
