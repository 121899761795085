import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge } from 'rxjs';
import { filter, map, share } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class TabbableService {
  readonly tab$: Observable<boolean>

  constructor() {
    const tabKey$ = fromEvent<KeyboardEvent>(document.body, 'keyup')
      .pipe(filter(e => e.keyCode === 9), map(() => true));

    const otherKey$ = fromEvent<KeyboardEvent>(document.body, 'keyup')
      .pipe(filter(e => e.keyCode >= 48), map(() => false));

    const click$ = fromEvent<Event>(document.body, 'click')
      .pipe(filter(e => (e as UIEvent).detail > 0), map(() => false));
      // can also be enter presses if detail === 0!
      // https://developer.mozilla.org/en-US/docs/Web/API/UIEvent/detail
      // https://github.com/facebook/react/issues/3907

    this.tab$ = merge(tabKey$, otherKey$, click$).pipe(share());
  }

}
