import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

interface ContactItem {
  icon: string;
  text: string;
}

@Component({
  selector: 'contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss', '../styles/enquire-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactPageComponent implements OnInit {

  expanded = false;
  contactItems: ContactItem[] = [
    {
      icon: 'pin',
      text: 'Botermelkdijk 462a<br>2900 Schoten<br>België'
    }, {
      icon: 'telephone',
      text: '<a href="tel:+32479568779">+32479568779</a>'
    }, {
      icon: 'mail',
      text: '<a href="mailto:info@zoemin.be">info@zoemin.be</a>'
    }, {
      icon: 'clock',
      text: 'Enkel op afspraak'
    }, {
      icon: 'internet',
      text: `BTW BE 0 897 364 420<br>
      KBC 733-0487124-20<br> 
      IBAN: BE28 7330 4871 2420<br> 
      BIC: KREDBEBB<br><br>
      RPR Antwerpen`
    }
  ]

  constructor() { }

  ngOnInit() {
  }

}
