import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { CommonPageService } from './common-page.service';
import { ActivatedRoute } from '@angular/router';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { Title } from '@angular/platform-browser';
import { PhotoService } from '../photo/photo.service';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, map, distinctUntilChanged } from 'rxjs/operators';
import { HeaderService } from '../header/header.service';
import { GalleryService } from '../gallery/gallery.service';
import { Photo } from '../photo/photo';
import { CommonPage } from './common-page';
import { getPrismicDocuments } from '../prismic/prismic-preload';

@Component({
  selector: 'common-page',
  templateUrl: './common-page.component.html',
  styleUrls: ['./common-page.component.scss', '../styles/icon-button.scss']
})
export class CommonPageComponent implements OnInit, OnDestroy {
  page: CommonPage;
  destroyed$ = new Subject<void>();

  constructor(private route: ActivatedRoute,
    private commonPageService: CommonPageService,
    private bootstrap: BootstrapService,
    private photoService: PhotoService,
    private headerService: HeaderService,
    private galleryService: GalleryService,
    private title: Title) {
    this.route.url.subscribe(e => {
      this.page = getPrismicDocuments<CommonPage>('common_page').find(p => p.uid === e[0].path);
      if (!this.page) console.error(`No common page with uid ${e[0].path}`);
      this.title.setTitle(`Zoemin Fotografie - ${this.page.title}`);

      const promise = this.commonPageService.fetchByUID(e[0].path)
      promise.then(p => {
        this.page = p;
        this.title.setTitle(`Zoemin Fotografie - ${p.title}`);
      });
    });

    fromEvent(window, 'scroll', {passive: true}).pipe(
      takeUntil(this.destroyed$),
      map(e => window.scrollY),
      distinctUntilChanged()
    ).subscribe(y => {
      y < 250 ? this.headerService.hideBorder() : this.headerService.showBorder();
    });
  }

  ngOnInit() {
  }
 
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getPhotoUrl(photo: Photo) {
    const o = this.photoService.getMostAppropriateUrlAndFormat(photo, (window.innerWidth / 3) * window.devicePixelRatio);
    return o.url;
  }

  onPhotoLoad(photo: Photo) {
    const o = this.photoService.getMostAppropriateUrlAndFormat(photo, (window.innerWidth / 3) * window.devicePixelRatio);
    this.photoService.markAsFetched(photo, o.format);
  }

  clickPhoto(photos: Photo[], photo: Photo) {
    this.galleryService.setPhotos(photos);
    this.galleryService.open();
  }

  loadedHeader() {
    this.bootstrap.pageLoaded$.next(true);
  }
}
