import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  readonly borderShown$ = new Subject<boolean>();

  constructor() { }

  hideBorder() {
    this.borderShown$.next(false);
  }

  showBorder() {
    this.borderShown$.next(true);
  }
}
