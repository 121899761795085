import { Component, OnInit, OnDestroy, ElementRef, ViewEncapsulation } from '@angular/core';
import { BeeService } from '../bee/bee.service';

@Component({
  selector: 'logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogoComponent implements OnInit, OnDestroy {
  text = require('./zoemin_logo_full_text_outlined.svg');

  constructor(private elementRef: ElementRef, private beeService: BeeService) { }

  ngOnInit() {
    const el = this.elementRef.nativeElement as HTMLElement
    el.addEventListener('mouseenter', () => this.beeService.enableFlapping());
    el.addEventListener('mouseleave', () => this.beeService.disableFlapping());
  }

  ngOnDestroy() {

  }

}
