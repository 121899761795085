import { Injectable } from '@angular/core';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { ReplaySubject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BeeTransporterLocation } from '../bee-transporter/bee-transporter.component';

@Injectable({
  providedIn: 'root'
})
export class BeeService {
  readonly flap$ = new ReplaySubject<boolean>(1);
  readonly sad$ = new ReplaySubject<boolean>(1);
  readonly move$ = new ReplaySubject<BeeTransporterLocation>(1);

  constructor(private bootstrap: BootstrapService, router: Router, route: ActivatedRoute) {
    this.bootstrap.done$.pipe(delay(4000))
    .subscribe(() => {
      this.disableFlapping();
    })

    bootstrap.routerData$.subscribe(data => {
      this.sad$.next(data.sadBee);
    });
  }

  enableFlapping() {
    this.flap$.next(true);
  }

  disableFlapping() {
    // const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    // const safari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1;
    // if (iOS || safari) return;
    this.flap$.next(false);
  }

  move(location: BeeTransporterLocation) {
    this.move$.next(location);
  }

}
