import { Component, OnInit, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { GalleryService } from '../gallery/gallery.service';
import { delay, merge, map, debounceTime } from 'rxjs/operators';
import { LinksService } from '../links/links.service';
import { MenuService } from '../menu/menu.service';
import { fromEvent, of } from 'rxjs';
import { HeaderService } from './header.service';
import { BeeService } from '../bee/bee.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', '../styles/enquire-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  expanded = false;
  links = this.linksService.links.filter(l => !l.only || l.only.indexOf('header') > -1);
  enquireText = "Stel een vraag";

  constructor(private bootstrap: BootstrapService,
    private elementRef: ElementRef,
    private galleryService: GalleryService,
    private linksService: LinksService,
    private menuService: MenuService,
    private headerService: HeaderService,
    private beeService: BeeService,
    private cd: ChangeDetectorRef) {
      this.menuService.state$.subscribe(b => {
        if (b) this.headerService.showBorder();
      });
  }

  ngOnInit() {
    const el = this.elementRef.nativeElement as HTMLElement;

    let done = false;
    let galleryOpen = false;

    this.bootstrap.done$.pipe(delay(300))
    .subscribe(() => {
      done = true;

      const el = this.elementRef.nativeElement as HTMLElement;
      if (!galleryOpen) {
        this.expanded = true;
        el.classList.add('expanded');
      }
    });


    this.galleryService.open$
    .subscribe(b => {
      galleryOpen = b;
      this.expanded = !b;
      if (done) {
        this.expanded ? el.classList.add('expanded') : el.classList.remove('expanded');
      }
    });

    fromEvent(window, 'resize').pipe(merge(of(true)), map(() => window.innerWidth))
    .subscribe(w => {
      // this.enquireText = 'Neem contact op';
      // this.enquireText = w < 450 ? 'Contact' : 'Stel een vraag';
      this.enquireText = w < 475 ? 'Contact' : 'Neem contact op';
      this.cd.markForCheck();
    });

    this.headerService.borderShown$.subscribe(s => {
      // console.log("Showing header border", s)
      s ? el.classList.add('border-shown') : el.classList.remove('border-shown');
    });
  }

  toggleMenu() {
    this.menuService.toggle();
  }

  logoFocus() {
    this.beeService.enableFlapping();
  }

  logoBlur() {
    this.beeService.disableFlapping();
  }
}
