import { Route, UrlSegment } from "@angular/router";
import { HomePageComponent } from "./home-page/home-page.component";
import { PortfolioPageComponent } from "./portfolio-page/portfolio-page.component";
import { PortfolioCategoryPageComponent } from "./portfolio-category-page/portfolio-category-page.component";
// import { AboutPageComponent } from "./about-page/about-page.component";
import { ContactPageComponent } from "./contact-page/contact-page.component";
// import { QuestionPageComponent } from "./question-page/question-page.component";
import { ReviewPageComponent } from "./review-page/review-page.component";
import { CommonPageComponent } from "./common-page/common-page.component";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";
import { InfoPageComponent } from "./info-page/info-page.component";
import { QuestionPageComponent } from "./question-page/question-page.component";
import { getPrismicDocuments } from "./prismic/prismic-preload";
import { CommonPage } from "./common-page/common-page";

export interface ZoeminRoute extends Route {
  data?: { hasLoadedHook?: boolean, sadBee?: boolean, title?: string, hideFooter?: boolean, hideHeaderBorder?: boolean, hasDynamicTitle?: boolean, disableZoom?: boolean }
}

// don't make the following into a function expression (with =>), AoT will fail
export function commonPageMatcher(url: UrlSegment[]) {
  return url.length === 1 && getPrismicDocuments<CommonPage>('common_page').find(cp => cp.uid === url[0].path) ? ({consumed: url}) : null;
}

// const routes: ZoeminRoute[] = [
//   { path: '', component: HomePageComponent, data: { title: 'Inge Nijs', hasLoadedHook: true, hideHeaderBorder: true } },
//   { path: 'portfolio', component: PortfolioPageComponent, data: { title: 'Portfolio', hideFooter: true, hideHeaderBorder: true } },
//   { path: 'portfolio/:category_uid', component: PortfolioCategoryPageComponent, data: { title: 'Portfolio', hasLoadedHook: true, hideFooter: true, hideHeaderBorder: true } },
//   // { path: 'over', component: AboutPageComponent, data: { title: 'Over' } },
//   { path: 'contact', component: ContactPageComponent, data: { title: 'Contact' } },
//   { path: 'info-en-tarieven', component: InfoPageComponent, data: { title: 'Info & Tarieven' } },
//   // { path: 'stel-een-vraag', component: QuestionPageComponent, data: { title: 'Stel een vraag' } },
//   { path: 'review', component: ReviewPageComponent, data: { title: 'Review' } },
//   { matcher: commonPageMatcher, component: CommonPageComponent, data: { hasDynamicTitle: true, hasLoadedHook: true } },
//   { path: '**', component: NotFoundPageComponent, data: { sadBee: true, title: '404' } }
// ];
// routes.forEach(o => o.loadChildren = `${(<HTMLMetaElement>document.querySelector('meta[name=app-module-path]')).content}#AppModule`);
// export { routes };

// export const routes: ZoeminRoute[] = [
//   {
//     path: '',
//     data: { title: 'Inge Nijs', hasLoadedHook: true, hideHeaderBorder: true },
//     children: [
//       { path: '', component: HomePageComponent, data: { title: 'Inge Nijs', hasLoadedHook: true, hideHeaderBorder: true } },
//       { path: 'portfolio', component: PortfolioPageComponent, data: { title: 'Portfolio', hideFooter: true, hideHeaderBorder: true } },
//       { path: 'portfolio/:category_uid', component: PortfolioCategoryPageComponent, data: { title: 'Portfolio', hasLoadedHook: true, hideFooter: true, hideHeaderBorder: true } },
//       { path: 'contact', component: ContactPageComponent, data: { title: 'Contact' } },
//       { path: 'info-en-tarieven', component: InfoPageComponent, data: { title: 'Info & Tarieven' } },
//       { path: 'review', component: ReviewPageComponent, data: { title: 'Review' } },
//       { matcher: commonPageMatcher, component: CommonPageComponent, data: { hasDynamicTitle: true, hasLoadedHook: true } },
//       { path: '**', component: NotFoundPageComponent, data: { sadBee: true, title: '404' } }
//     ]
//   }
// ]

export const routes: ZoeminRoute[] = [
  { path: '', component: HomePageComponent, data: { title: 'Inge Nijs', hasLoadedHook: true, hideHeaderBorder: true } },
  { path: 'portfolio', component: PortfolioPageComponent, data: { title: 'Portfolio', hideFooter: true, hideHeaderBorder: true } },
  { path: 'portfolio/:category_uid', component: PortfolioCategoryPageComponent, data: { title: 'Portfolio', hasLoadedHook: true, hideFooter: true, hideHeaderBorder: true, hasDynamicTitle: true, disableZoom: true } },
  { path: 'contact', component: ContactPageComponent, data: { title: 'Contact' } },
  { path: 'info-en-tarieven', component: InfoPageComponent, data: { title: 'Info & Tarieven', hasLoadedHook: true } },
  { path: 'review', component: ReviewPageComponent, data: { title: 'Review' } },
  { path: 'faq', component: QuestionPageComponent, data: { title: 'FAQ' } },
  { matcher: commonPageMatcher, component: CommonPageComponent, data: { hasDynamicTitle: true, hasLoadedHook: true, hideHeaderBorder: true  } },
  { path: '**', component: NotFoundPageComponent, data: { sadBee: true, title: '404' } }
];
