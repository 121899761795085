import { CommonPage, CommonPageTextSlice, CommonPageLinkButtonSlice, CommonPageGallerySlice } from "./common-page";
import { fromDocument as photoFromDocument } from '../photo/photo-util';
import { RichText } from "../prismic/prismic.service";

export function fromDocument(result: any): CommonPage {
  const page: CommonPage = {
    uid: result.uid,
    coverPhoto: photoFromDocument(result.data.cover_photo),
    coverColor: result.data.cover_color,
    title: result.data.title,
    slices: result.data.body.map(slice => {
      const t = slice.slice_type;
      const data = slice.primary;
      switch(t) {
        case 'text': {
          const s: CommonPageTextSlice = {
            type: t,
            value: {
              html: RichText.asHtml(data.body)
            }
          }

          return s;
        }
        case 'link_button': {
          const s: CommonPageLinkButtonSlice = {
            type: t,
            value: {
              link: data.link,
              text: data.text
            }
          }

          return s;
        }
        case 'gallery': {
          const s: CommonPageGallerySlice = {
            type: t,
            value: {
              photos: slice.items.map(i => photoFromDocument(i.image))
            }
          }

          return s;
        }
      }
    })
  }

  return page;
}
