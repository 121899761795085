import { Component, OnInit, ViewEncapsulation, ElementRef, AfterViewInit, Input, ViewChild, SimpleChanges, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { BeeService } from './bee.service';

@Component({
  selector: 'bee',
  templateUrl: './bee.component.html',
  styleUrls: ['./bee.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeeComponent implements OnInit, AfterViewInit {
  @ViewChild('svgContainer', {static: true}) svgContainerEl: ElementRef<HTMLDivElement>;
  private beeSvgFile: string = require('./zoemin_logo_unrotated.svg');
  private kickstartingFlapping: boolean = true;
  private _flapping: boolean;
  private queuedStopFlapping: boolean;
  private startedFlappingTime: Date;
  
  constructor(private elementRef: ElementRef<HTMLDivElement>, private beeService: BeeService) {}

  set flapping(value: boolean) {
    const actualValue = value || this.kickstartingFlapping;
    const el = this.svgContainerEl.nativeElement as HTMLDivElement;
    if (!this._flapping && actualValue) {
      el.setAttribute('flapping', '');
      this.startedFlappingTime = new Date();
    }
    if (!actualValue) this.queueStopFlapping();
    this._flapping = value;
  }

  get flapping() {
    return this._flapping;
  }

  set sad(value: boolean) {
    const el = this.svgContainerEl.nativeElement as HTMLDivElement;
    value ? el.setAttribute('sad', '') : el.removeAttribute('sad');
  }

  get sad() {
    const el = this.svgContainerEl.nativeElement as HTMLDivElement;
    return !!el.getAttribute('sad');
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.svgContainerEl.nativeElement.innerHTML = this.beeSvgFile;

    const a = "Bij Hoofd VleugelLinks VleugelRechts VoelsprietLinks VoelsprietRechts Angel Mond".split(' ');

    for (const qs of a) {
      const e = this.elementRef.nativeElement.querySelector(`#${qs}`) as SVGElement;
      if (e.style) {
        e.style['transformBox'] = 'fill-box';
      } else {
        console.log(e);
      }
    }


    this.flapping = true;
    setTimeout(() => {
      this.kickstartingFlapping = false;
      this.flapping = this._flapping;
    }, 100);
    this.beeService.flap$.subscribe(b => {
      this.flapping = b;
    });
    this.beeService.sad$.subscribe(b => {
      this.sad = b;
    });

    const el = this.svgContainerEl.nativeElement as HTMLDivElement;

    el.querySelector('#VleugelLinks').addEventListener('animationiteration', (e: AnimationEvent) => {
      const elapsedTime = Math.round(e.elapsedTime * 10);
      const even = (elapsedTime / 4) % 2 === 0; // animation duration = 400ms
      
      if (this.queuedStopFlapping && !even) {
        // ensure minimum 2 seconds of flapping
        const waitUntilTime = this.startedFlappingTime.getTime() + 2000;
        if (waitUntilTime > new Date().getTime()) return;

        this.queuedStopFlapping = false;
        el.removeAttribute('flapping');
      }
    });
  }

  queueStopFlapping() {
    this.queuedStopFlapping = true;
  }
}

const isAllEqual = (arr) => {
  return !!arr.reduce(function(a, b){ return (a === b) ? a : NaN; });
}
