import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private lastState = false;
  readonly state$ = new Subject<boolean>();

  constructor() { }

  toggle() {
    this.state$.next(this.lastState = !this.lastState);
  }

  close() {
    this.state$.next(this.lastState = false);
  }

}
