import { CommonModule } from '@angular/common';
import { NgModule, Injectable, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms'

import { HomePageComponent } from './home-page/home-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { PortfolioPageComponent } from './portfolio-page/portfolio-page.component';
import { AboutPageComponent } from './about-page/about-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { PortfolioCategoryPageComponent } from './portfolio-category-page/portfolio-category-page.component';
import { ReviewPageComponent } from './review-page/review-page.component';
import { CommonPageComponent } from './common-page/common-page.component';
import { EnquireFormComponent } from './enquire-form/enquire-form.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { BackgroundImageDirective } from './background-image/background-image.directive';
import { SharedModule } from './shared.module';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { TrackDirective } from './track/track.directive';
import { CommonPageHeaderComponent } from './common-page-header/common-page-header.component';
import { QuestionPageComponent } from './question-page/question-page.component';
import { CanFocusDirective } from './can-focus/can-focus.directive';

@NgModule({
  declarations: [
    HomePageComponent,
    NotFoundPageComponent,
    PortfolioPageComponent,
    AboutPageComponent,
    ContactPageComponent,
    SlideshowComponent,
    PortfolioCategoryPageComponent,
    ReviewPageComponent,
    CommonPageComponent,
    EnquireFormComponent,
    InfoPageComponent,
    BackgroundImageDirective,
    TrackDirective,
    CommonPageHeaderComponent,
    QuestionPageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    SharedModule
   ],
   exports: [
    RouterModule
  ]
})
export class AppModule {
}
