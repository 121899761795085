import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, PreloadAllModules, Route } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared.module';
import { AppComponent } from './app.component';

const routes: Route[] = [
  {
    path: '',
    loadChildren: () => import('./app.module').then(m => m.AppModule)
    // loadChildren: () => import('./app.module').then(m => m.AppModule)
  }
];
const routing: ModuleWithProviders<RouterModule>  = RouterModule.forRoot(routes, {
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: PreloadAllModules
});

@NgModule({
  imports: [
    BrowserModule,
    routing,
    HttpClientModule,
    SharedModule
  ],
  bootstrap: [AppComponent]
})
export class LoaderModule { }
