import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './app/environment/environment';
import { LoaderModule } from './app/loader.module';

if (environment.is('production') || environment.is('test')) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(LoaderModule);
