import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[canFocus]'
})
export class CanFocusDirective {
  private _canFocus: boolean;

  @Input()
  set canFocus(value: boolean) {
    this._canFocus = value;
    (this.elementRef.nativeElement as HTMLElement).setAttribute('tabindex', value ? '0' : '-1');
  }

  get canFocus() {
    return this._canFocus;
  }

  constructor(private elementRef: ElementRef) { }

}
