import { Component, OnInit, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieAcceptationService } from '../cookie-acceptation/cookie-acceptation.service';

@Component({
  selector: 'cookie-notifier',
  templateUrl: './cookie-notifier.component.html',
  styleUrls: ['./cookie-notifier.component.scss']
})
export class CookieNotifierComponent implements OnInit {

  constructor(private elementRef: ElementRef<HTMLElement>,
    private ca: CookieAcceptationService) { }

  ngOnInit() {
    const el = this.elementRef.nativeElement;
    this.ca.acceptation$.subscribe(v => {
      v ? el.classList.remove('visible') : el.classList.add('visible');
    });
  }

  clickOk() {
    this.ca.accept();
  }

}
