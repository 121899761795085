import { Injectable, ElementRef, NgZone } from '@angular/core';
import { ReplaySubject } from 'rxjs';

const APP_ID = '6Ld1bpMUAAAAAEpPqaZfeK41VrFB8flcH_FJ9QhR';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  loaded$ = new ReplaySubject<boolean>(1);
  private recaptchaBadge: ElementRef<HTMLDivElement>;
  private grecaptcha;
  private clientId;
  
  constructor(private zone: NgZone) {
    this.zone.runOutsideAngular(() => {
      (window as any).onRecaptchaLoad = () => {
        this.grecaptcha = (window as any).grecaptcha;
        this.clientId = this.grecaptcha.render(this.recaptchaBadge.nativeElement, {
          'sitekey': APP_ID,
          'badge': 'inline',
          'size': 'invisible',
          // 'theme': 'dark'
        });
        this.loaded$.next(true);
        console.log('Recaptcha loaded', this.clientId);
      }
    });
  }

  load(recaptchaBadge: ElementRef<HTMLDivElement>) {
    this.zone.runOutsideAngular(() => {
      this.recaptchaBadge = recaptchaBadge;
      const oldScript = document.getElementById('recaptcha-script');
      // if (oldScript) document.head.removeChild(oldScript);

      if (!oldScript) {
        const s = document.createElement('script');
        s.id = 'recaptcha-script';
        s.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit';
        // s.async = true;
        // s.defer = true;
        document.head.appendChild(s);
      } else {
        console.log('Already loaded recaptcha');
        setTimeout(() => (window as any).onRecaptchaLoad(), 1000);
      }
    });
  }

  fetchToken(): Promise<string> {
    return (window as any).grecaptcha.execute(this.clientId, {action: 'contact'});
  }
}
