import { Directive, ElementRef, Input } from '@angular/core';
import { AnalyticsService } from '../analytics/analytics.service';

@Directive({
  selector: '[track]'
})
export class TrackDirective {
  @Input('track') track: string;

  constructor(private el: ElementRef<HTMLElement>, private analytics: AnalyticsService) {
    el.nativeElement.addEventListener('click', () => {
      const data = this.track.split(',');
      analytics.track(data[0], data[1]);
    });
  }

}
