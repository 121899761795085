import { Injectable } from '@angular/core';
import { PhotoId, PhotoFormats, Photo, PhotoFormat } from './photo';
import { formatForSize } from './photo-util';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  private fetchedFormatsMap = new Map<PhotoId, PhotoFormats>();

  constructor() {
    (window as any).fetchedFormatsMap = this.fetchedFormatsMap;
  }

  url(photo: Photo, format: PhotoFormat = PhotoFormat.HD): string {
    const maFormat = this.mostAppropriateFormat(photo, format);
    return photo.urls[maFormat];
  }

  mostAppropriateFormat(photo: Photo, format: PhotoFormat = PhotoFormat.HD): PhotoFormat {
    const fetchedFormats = this.fetchedFormatsMap.get(photo.id) || new Map<PhotoFormat, boolean>();
    if (fetchedFormats.get(format)) {
      return format;
    } else {
      for (let i: PhotoFormat = 0; i <= format; i++) {
        if (fetchedFormats.get(i)) return i;
      }
      this.fetchedFormatsMap.set(photo.id, fetchedFormats);
      return format;
    }
  }

  isFetched(photo: Photo, format: PhotoFormat): boolean {
    const m = this.fetchedFormatsMap.get(photo.id);
    return m && m.get(format);
  }

  markAsFetched(photo: Photo, format: PhotoFormat): void {
    console.log('Marked', photo, 'with format', format, 'as fetched');
    const fetchedFormats = this.fetchedFormatsMap.get(photo.id) || new Map<PhotoFormat, boolean>();
    fetchedFormats.set(format, true);
    this.fetchedFormatsMap.set(photo.id, fetchedFormats);
  }

  getMostAppropriateUrlAndFormat(photo: Photo, width: number) {
    const naiveFormat = formatForSize(width); // probably window.innerWidth * window.devicePixelRatio
    const format = this.mostAppropriateFormat(photo, naiveFormat);
    const url = this.url(photo, format);
    return {url, format};
  }
}
