import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  readonly shown$ = new Subject();
  
  constructor() { }

  ngOnInit() {
  }

  show() {
    this.shown$.next(true); 
  }

  hide() {
    this.shown$.next(false); 
  }
}
