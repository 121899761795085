import { Category } from "./category";
import { fromDocument as photoFromDocument, fromDocumentArray } from "../photo/photo-util";

export function fromDocument(pre: any) {
  const data = (pre as any).data;
  const category: Category = {
    uid: (pre as any).uid,
    name: data.name,
    frontImage: photoFromDocument(data.front_image),
    frontColor: data.front_color,
    index: data.index || 0,
    // TODO : remove ternary operator here
    photos: !data.gallery[0].image.url ? [] : fromDocumentArray(data.gallery.map(d => ({photo: d.image})))
  }
  return category;
}
