import { Directive, Input, OnDestroy, ElementRef } from '@angular/core';
import { TabbableService } from './tabbable.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[tabbable]'
})
export class TabbableDirective implements OnDestroy {
  private subscription: Subscription;

  constructor(private elementRef: ElementRef, private tabbableService: TabbableService) {
    this.subscription = this.tabbableService.tab$
      .subscribe(b => {
        const el = this.elementRef.nativeElement as HTMLElement;
        b ? el.classList.add('tabbed') : el.classList.remove('tabbed');
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
