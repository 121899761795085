import { Injectable } from '@angular/core';
import { PrismicService } from '../prismic/prismic.service';
import { fromDocument } from './common-page-util';
import { CommonPage } from './common-page';

@Injectable({
  providedIn: 'root'
})
export class CommonPageService {
  private commonPages: CommonPage[] = [];

  constructor(private prismic: PrismicService) {
  }

  async fetchByUID(uid: string): Promise<CommonPage> {
    const cachedPage = this.commonPages.find(cp => cp.uid === uid);
    if (cachedPage) return cachedPage;

    const api = await this.prismic.api();
    const response = await api.getByUID('common_page', uid);
    console.log(response);
    return fromDocument(response);
  }
}
