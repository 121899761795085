import { Injectable } from '@angular/core';
const Prismic = require('prismic-javascript').default;
import { RichText } from 'prismic-dom';

@Injectable({
  providedIn: 'root'
})
export class PrismicService {

  private _api; 

  constructor() { }

  api() {
    return this._api = this._api || Prismic.api("https://zoemin.prismic.io/api/v2");
  }

}

export const Predicates = Prismic.Predicates;
export { RichText };
