import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { BeeComponent } from './bee/bee.component';
import { BeeTransporterComponent } from './bee-transporter/bee-transporter.component';
import { MaskComponent } from './mask/mask.component';
import { CookieNotifierComponent } from './cookie-notifier/cookie-notifier.component';
import { GalleryComponent } from './gallery/gallery.component';
import { IconComponent } from './icon/icon.component';
import { MenuComponent } from './menu/menu.component';
import { CanFocusDirective } from './can-focus/can-focus.directive';
import { TabbableDirective } from './tabbable/tabbable.directive';
import { FooterComponent } from './footer/footer.component';
import { SafePipe } from './safe/safe.pipe';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { LogoComponent } from './logo/logo.component';
import { Title } from '@angular/platform-browser';

@NgModule({
  declarations: [
    HeaderComponent,
    LogoComponent,
    AppComponent,
    BeeComponent,
    BeeTransporterComponent,
    MaskComponent,
    CookieNotifierComponent,
    GalleryComponent,
    IconComponent,
    MenuComponent,
    CanFocusDirective,
    TabbableDirective,
    FooterComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  providers: [
    Title
  ],
  exports: [
    AppComponent,
    BeeComponent,
    BeeTransporterComponent,
    MaskComponent,
    CookieNotifierComponent,
    GalleryComponent,
    IconComponent,
    MenuComponent,
    CanFocusDirective,
    TabbableDirective,
    FooterComponent,
    SafePipe
  ]
})
export class SharedModule { }
