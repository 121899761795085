import { Component, OnInit, ElementRef } from '@angular/core';
import { MenuService } from './menu.service';
import { LinksService } from '../links/links.service';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  opened: boolean = false;
  links = []

  constructor(private menu: MenuService, private elementRef: ElementRef, linksService: LinksService) {
    const el = (elementRef.nativeElement as HTMLMenuElement);
    const l = el.classList
    this.menu.state$.subscribe(b => this.opened = b);
    this.menu.state$.subscribe(b => b ? l.add('opened') : l.remove('opened'));
    this.links = linksService.links.filter(l => !l.only || l.only.indexOf('menu') > -1);
  }

  ngOnInit() {
  }

}
