import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare const _zoeminStartTime: number;

const GOOGLE_ANALYTICS_ID = 'UA-72660054-1';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private router: Router) {
    this.router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  start() {
    this.load();
  }
  
  load() {
    const w = window as any;
    w.ga=w.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
    ga('create', GOOGLE_ANALYTICS_ID, 'auto');
    ga('set', 'anonymizeIp', true);
  }

  track(category: string, action: string) {
    ga('send', 'event', {
      eventCategory: category,
      eventAction: action
    });
  }

  time(category: string, name: string) {
    if (!window.performance) return;
    const time = Math.round(window.performance.now() - _zoeminStartTime);
    ga('send', 'timing', category, name, time);
  }
}
